import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import {AuthenticatedContentWrapper} from "./AuthenticatedContentWrapper";
import {Paper} from "@mui/material";
import Box from "@mui/material/Box";

export const MainContentAuthWrapper = () => {
        return (
        <>
            <AuthenticatedTemplate>
                <AuthenticatedContentWrapper />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <center>
                <Paper variant="outlined"
                       sx={{height: 600, width:600, mt: 5, pt: 5, pb: 5}}>
                    <center>
                        <Box component="img" alt="TF Login" src="img/tf_landing_signin.jpg"
                             sx={{height: 512, width:512}}
                        />
                    </center>
                    <h5>
                        <center>
                            Welcome, sign in to access the forge!
                        </center>
                    </h5>
                </Paper>
                    </center>

            </UnauthenticatedTemplate>
        </>
    );
};