import React from 'react';
import {MainContentAuthWrapper} from "./components/content/MainContentAuthWrapper";
import NavMenuComponent from './components/navMenu/navMenu.component';
import './App.css';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {TFTheme} from "./TFTheme";

function App() {
  return (
      <ThemeProvider theme={TFTheme}>
          <CssBaseline/>
          <NavMenuComponent/>
          <MainContentAuthWrapper />
      </ThemeProvider>
  );
}

export default App;
