import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";


export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    return (
        <Button color={"inherit"} onClick={() => handleLogout()}>Logout</Button>
    );
};