import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {SignOutButton} from "./SignOutButton";
import {SignInButton} from "./SignInButton";
import {useIsAuthenticated} from "@azure/msal-react";
import {Container} from "@mui/material";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";

export default function NavMenuComponent(){
    const isAuthenticated = useIsAuthenticated();
    const pages = [
        {name: "Home", needsAuth: false},
        {name: "About Me", needsAuth: true},
        {name: "Portfolio", needsAuth: true},
    ];

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const handleOpenNavMenu = event => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return(
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="img" sx={{height: 50, width:200, display: { xs:'none', md:'flex'}, mr: 2 }} alt="TF Logo" src="img/TF.png"></Box>
                    <Box sx={{ flexGrow: 1, display: {xs: 'flex', md: 'none'} }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color={"inherit"}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id={"menu-appbar"}
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'}
                            }}
                            >
                            {pages.map((page) => (
                                <MenuItem   key={page.name} onClick={handleCloseNavMenu} hidden={page.needsAuth ? !isAuthenticated : false}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box component="img" sx={{ height: 50, width:200, display: { xs:'flex', md:'none'}, mr: 2 }} alt="TF Logo" src="img/TF.png"></Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
                        {pages.map((page) => (
                            <Button key={page.name} onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block'}} hidden={page.needsAuth ? !isAuthenticated : false}>
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

