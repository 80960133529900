import {createTheme} from "@mui/material";

export const TFTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#e78200',
            contrastText: '#045336',
        },
        secondary: {
            main: '#c0ff00',
        },
        background:{
            default: '#121212',
        }
    },
    typography: {
        fontFamily: 'Roboto',
        h6: {
            fontFamily: 'Audiowide',
        },
        h5: {
            fontFamily: 'Audiowide',
        },
        h4: {
            fontFamily: 'Audiowide',
        },
        h3: {
            fontFamily: 'Audiowide',
        },
        h2: {
            fontFamily: 'Audiowide',
        },
        h1: {
            fontFamily: 'Audiowide',
        },
    },
});